'use client'

import { MouseEvent, useCallback, useEffect, useState } from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import {
  ConfigurableProductFragment,
  ConfigurableProductVariantFragment,
  ProductLabelFragment,
  ProductLabelV2Fragment,
  ProductStockStatus,
} from '@/api'
import { Currency } from '@/components/currency'
import { ImageLabels } from '@/components/image-labels'
import { Rating } from '@/components/rating'
import { ProductName } from '@/components/product-name'
import { useCartContext } from '@/providers'
import {
  obsoleteGetInitialConfigurableProductVariant,
  getInitialGiftCardProductAmount,
} from '@/providers/product-data/utils'
import { arePricesDifferentInVariants, obsoloteGetPrice } from '@/utils'
import { ObosoleteAddToCartButton } from '../obsolete-add-to-cart-button'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { ObsoloteProductListItemWrapper } from './obsolote-product-list-item-wrapper'
import {
  ConfigurableVariantsData,
  GiftCardAmountsData,
  PriceRangeData,
  ProductManufacturerInfoData,
  ProductType,
  ThumbnailData,
} from '@/common/types/product-types'
import { ImageLabelsV2 } from '../image-labels-v2'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'

function getMinimumInStockPrice(
  variants: ConfigurableProductVariantFragment[],
): number | null {
  const inStockVariants = variants.filter((variant) => {
    return (
      variant.product?.stock_status === ProductStockStatus.InStock &&
      variant.product?.price_range.minimum_price.final_price.value !==
        undefined &&
      variant.product?.price_range.minimum_price.final_price.value !== null
    )
  })

  if (inStockVariants.length === 0) {
    return null
  }

  const prices = inStockVariants
    .map(
      (variant) => variant.product?.price_range.minimum_price.final_price.value,
    )
    .filter((price): price is number => price != null)

  return prices.length > 0 ? Math.min(...prices) : null
}

export type ObsoloteProductListItemProps = {
  product: ObsoloteProductListItemData
  onPurchase?: (product: ObsoloteProductListItemData) => void
  baseUrl: string
}

export function ObsoloteProductListItem({
  product,
  onPurchase,
  baseUrl,
}: ObsoloteProductListItemProps): JSX.Element {
  const t = useTranslations('Product')
  const { isCartRefetching, addToCart } = useCartContext()
  const { configurableProductVariant } = useProductDataContext()
  const disabled = isCartRefetching || !addToCart
  const labelsV1 = (product.product_labels?.filter(
    (label) => !!label && label.is_visible,
  ) ?? []) as ProductLabelFragment[]
  const labelsV2 = (product.gb_product_labels ?? []) as ProductLabelV2Fragment[]
  const [variants, setVariants] = useState<
    ConfigurableProductVariantFragment[]
  >([])
  const isGiftCardProduct = product.__typename === ProductType.GiftCard
  const isPriceRange = variants.length > 1 || isGiftCardProduct

  useEffect(() => {
    if (!isGiftCardProduct) {
      const productVariants = (product?.configurable_variants?.filter(
        (variant) =>
          variant?.product?.enabled &&
          variant?.product?.stock_status === ProductStockStatus.InStock,
      ) ?? []) as ConfigurableProductVariantFragment[]

      setVariants(productVariants)
    }
  }, [product, isGiftCardProduct])

  const handleAddToCart = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (addToCart) {
        event.preventDefault()
        event.stopPropagation()

        if (isPriceRange) {
          if (onPurchase) {
            const price = obsoloteGetPrice(product)
            const productTrackingData = {
              id: Number(product.id),
              name: product.name || '',
              brand:
                'manufacturer_info' in product
                  ? product.manufacturer_info?.name ?? ''
                  : 'GymBeam',
              category: product.breadcrumb_en ?? '',
              price: price.finalPrice?.value_excl_tax ?? NaN,
            }

            gtmTracker.trackProductVariantsDetail(
              productTrackingData,
              price.finalPrice?.value_excl_tax ?? NaN,
              price.finalPrice?.currency || '',
              Number(configurableProductVariant?.product?.id),
            )

            onPurchase(product)
          }
        } else {
          if (variants.length === 1) {
            const isConfigurableProduct =
              product?.__typename === ProductType.Configurable

            if (isGiftCardProduct) {
              addToCart(
                {
                  isGiftCard: true,
                  sku: product?.sku ?? '',
                  quantity: 1,
                  selectedOptions: [
                    getInitialGiftCardProductAmount(product.giftcard_amounts)
                      ?.uid ?? '',
                  ],
                  // additional info
                  productName: product?.name ?? '',
                },
                { addingRecommendedProduct: true },
              )
              return
            }

            if (isConfigurableProduct) {
              const initialVariant =
                obsoleteGetInitialConfigurableProductVariant(
                  product.configurable_variants,
                )
              const { finalPrice } = obsoloteGetPrice(initialVariant?.product)

              addToCart({
                productName: product.name ?? '',
                parentSku: product.sku ?? '',
                sku: initialVariant?.product?.sku ?? '',
                quantity: 1,
                brand: product.manufacturer_info?.name ?? '',
                price: finalPrice?.value_excl_tax ?? NaN,
                category: product.breadcrumb_en ?? '',
              })
            }
          } else {
            // recommended product
            addToCart(
              {
                parentSku: product.parent_sku || '',
                sku: product.sku ?? '',
                quantity: 1,
                productName: product.name ?? '',
                brand: product.manufacturer_info?.name ?? '',
              },
              { addingRecommendedProduct: true },
            )
          }
        }
      }
    },
    [
      product,
      isPriceRange,
      variants.length,
      configurableProductVariant?.product,
      isGiftCardProduct,
      onPurchase,
      addToCart,
    ],
  )

  const isPriceRangeWithDifferentValues = arePricesDifferentInVariants(
    variants.map(
      (variant) => variant.product?.price_range.minimum_price.final_price.value,
    ),
  )

  return (
    <ObsoloteProductListItemWrapper
      name={product.name}
      canonicalUrl={product.canonical_url}
      baseUrl={baseUrl}
    >
      <div className="relative max-h-[215px] lg:h-[215px] w-fit md:w-auto">
        <div>
          <Image
            src={product.thumbnail?.url?.medium ?? ''}
            alt={product.thumbnail?.label ?? 'alternative'}
            priority
            width={500}
            height={500}
            className="w-full lg:h-[215px] max-h-[215px] aspect-square object-contain"
          />

          {product.stock_status === ProductStockStatus.InStock ? (
            <ObosoleteAddToCartButton
              disabled={disabled}
              onClick={handleAddToCart}
              aria-label={t('common.button.addToCart')}
            />
          ) : (
            <span
              title={t('common.status.outOfStock')}
              className={twJoin(
                'absolute left-0 bottom-[5px]',
                'flex justify-center items-center',
                'h-11 w-full',
                'text-white bg-shadow uppercase font-bold',
              )}
            >
              {t('common.status.outOfStock')}
            </span>
          )}
        </div>

        {!product.gb_product_labels?.length && !!labelsV1?.length && (
          <ImageLabels labels={labelsV1} recommendedOffset />
        )}
        {!!product.gb_product_labels?.length && (
          <ImageLabelsV2 labels={labelsV2} />
        )}
      </div>
      <ProductName>{product.name}</ProductName>

      <Rating
        value={product.rating_summary}
        voteCount={product.review_count}
        className="ml-[-5px] mt-3"
        voteCountClassName="font-bold text-xs m-0"
      />

      <Currency
        className={twJoin(
          'text-sm font-bold text-secondary mt-1 h-[1em] block',
        )}
        currency={product.price_range?.minimum_price?.final_price?.currency}
        price={
          getMinimumInStockPrice(variants) ??
          product.price_range?.minimum_price?.final_price?.value
        }
        isPriceRange={isPriceRangeWithDifferentValues || isGiftCardProduct}
      />
    </ObsoloteProductListItemWrapper>
  )
}

export type ObsoloteProductListItemData = {
  __typename?: string
  id?: number | null
  name?: string | null
  product_labels?: ConfigurableProductFragment['product_labels']
  gb_product_labels?: ConfigurableProductFragment['gb_product_labels']
  breadcrumb_en?: string | null
  manufacturer_info?: ProductManufacturerInfoData
  configurable_variants?: ConfigurableVariantsData
  thumbnail?: ThumbnailData
  price_range?: PriceRangeData
  rating_summary: number
  review_count?: number
  sku?: string | null
  parent_sku?: string | null
  stock_status?: ProductStockStatus | null
  giftcard_amounts?: GiftCardAmountsData
  canonical_url?: string | null
}

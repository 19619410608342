'use client'

import React, { useMemo } from 'react'

import { ProductStockStatus } from '@/api'
import {
  ProductListItemData,
  obsoleteIsConfigurableProductListItem,
  obsoleteIsGiftCardProductListItem,
} from '@/common/types/product-types'
import { ProductDetailStockSubscribe } from '@/modules/product/components/detail/components/stock-subscribe'
import { ObsoletePurchaseDialogConfigurableVariants } from './obsolete-purchase-dialog-configurable-variants'
import { ObsoletePurchaseDialogGiftCardVariants } from './obsolete-purchase-dialog-gift-card-variants'
import { AddToCartEventHandlerParams } from '@/providers/cart/utils/add-to-cart.utils'
import { obsoloteGetPrice } from '@/common/utils'
import { Modal } from '../modal/modal'

export type PurchaseDialogProps = {
  children?: JSX.Element
  title?: string
  productData?: ProductListItemData
  isVisible: boolean
  onClose?: () => void
}

export function ObsoletePurchaseDialog({
  onClose,
  productData,
  isVisible,
}: PurchaseDialogProps): JSX.Element | null {
  const handleOnAddToCart = async ({
    count,
    product,
    variant,
    addToCart,
  }: AddToCartEventHandlerParams) => {
    const { finalPrice } = obsoloteGetPrice(variant?.product)

    await addToCart({
      productName: product?.name ?? '',
      parentSku: product?.sku ?? '',
      sku: variant?.product?.sku ?? '',
      quantity: count,
      brand: product?.manufacturer_info?.name ?? '',
      price: finalPrice?.value_excl_tax
        ? Math.round(finalPrice?.value_excl_tax * 100) / 100
        : undefined,
      category: product?.breadcrumb_en ?? '',
    })
  }

  const isLongTermUnavailable = productData?.long_term_unavailable !== 0
  const isSomeVariantAvailable = useMemo(
    () =>
      productData !== undefined &&
      obsoleteIsConfigurableProductListItem(productData) &&
      productData.configurable_variants?.some(
        (variant) =>
          variant?.product?.enabled &&
          variant?.product?.stock_status === ProductStockStatus.InStock,
      ),
    [productData],
  )
  const inStock = useMemo(
    () =>
      !isLongTermUnavailable &&
      (obsoleteIsGiftCardProductListItem(productData) ||
        isSomeVariantAvailable),

    [isLongTermUnavailable, productData, isSomeVariantAvailable],
  )

  return productData ? (
    <Modal isOpen={isVisible} onClose={() => onClose?.()} className="w-[500px]">
      {inStock ? (
        <>
          {obsoleteIsConfigurableProductListItem(productData) && (
            <ObsoletePurchaseDialogConfigurableVariants
              configurableProductData={productData}
              isLongTermUnavailable={isLongTermUnavailable}
              onClose={onClose}
              onAddToCart={handleOnAddToCart}
              isInStock
            />
          )}
          {obsoleteIsGiftCardProductListItem(productData) && (
            <ObsoletePurchaseDialogGiftCardVariants
              productData={productData}
              onClose={onClose}
            />
          )}
        </>
      ) : (
        <ProductDetailStockSubscribe />
      )}
    </Modal>
  ) : null
}
